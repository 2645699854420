export class CompanyUser {
    UserId: number;
    ClientId: number;
    EmailId: string;
    UserName: string;
    Password: string;
    RoleId: number;
    RoleName: string;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
    UserAssessmentId: number;
    AssessmentName: string;
    TotalScore?: string;
    TotalQuestions?: string;
    AssessmentCreatedDate? : Date;
}