export class RegisterAssessment {
    UserId: number;
    ClientId: number;
    AssessmentId: number;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
    CCEmailIds: string;
}