import { AppConfig } from './app-config';

const BASE_URL = `https://api.qa.myskilltest.com/`;
//const BASE_URL = `http://localhost:53459/`;

export const localAppConfig: AppConfig = {
    endpoints: {
        register: `${BASE_URL}api/account/register`,
        users: `${BASE_URL}token`,
        userDetails: `${BASE_URL}api/UserDetails/UserDetails`,
        domain: `${BASE_URL}api/AssessmentDomains`,
        questiontype: `${BASE_URL}api/QuestionTypes`,
        complexity: `${BASE_URL}api/Complexities`,
        questions: `${BASE_URL}api/Questions`,
        answers: `${BASE_URL}api/Answers`,
        companyUser: `${BASE_URL}api/Users`,
        role: `${BASE_URL}api/Roles`,
        assessment: `${BASE_URL}api/Assessments`,
        assessmentLib: `${BASE_URL}api/AssessmentLibraries`,
        assessmentQues: `${BASE_URL}api/AssessmentQuestions`,
        libraryQues: `${BASE_URL}api/LibraryQuestions`,
        registerAssessment: `${BASE_URL}api/UserAssessment/register`,
        subUsersList: `${BASE_URL}api/UserDetails/getSubUsersList`,
        candidateList: `${BASE_URL}api/UserDetails/getCandidatesList`,
        changePassword: `${BASE_URL}api/UserDetails/changePassword`,
        forgotPassword: `${BASE_URL}api/account/forgotPassword`,
        getUserAssessment: `${BASE_URL}api/UserAssessment/getAssessments`,
        startAssessment: `${BASE_URL}api/UserAssessment/start`,
        pauseAssessment: `${BASE_URL}api/UserAssessment/pause`,
        restartAssessment: `${BASE_URL}api/UserAssessment/restart`,
        completeAssessment: `${BASE_URL}api/UserAssessment/complete`,
        abortAssessment: `${BASE_URL}api/UserAssessment/abort`,
        timeoutAssessment: `${BASE_URL}api/UserAssessment/timeout`,
        incrementTimer: `${BASE_URL}api/UserAssessment/IncrementTimer`,
        assessmentDomainLimitTracker: `${BASE_URL}api/UserAssessment/AssessmentDomainLimitTracker`,
        userAssessmentTracker: `${BASE_URL}api/UserAssessment/UserAssessmentTracker`,
        getNextQuestion: `${BASE_URL}api/UserAssessment/GetNextQuestion`,
        userAssessment: `${BASE_URL}api/UserAssessment`,
        submitAnswer: `${BASE_URL}api/UserAssessment/SubmitAnswer`,
        getAssessmentById: `${BASE_URL}api/UserAssessment/getAssessmentById`,
        getQuestionsForAssessment: `${BASE_URL}api/Questions/GetQuestionsForAssessment`,
        getAssessmentQuestionById: `${BASE_URL}api/AssessmentQuestions/AssessmentQuestionsByAssessmentId`,
        getUserAnswerDetails: `${BASE_URL}api/UserAssessmentIAI/getUserAnswerDetails`,
        getAssessmentResults: `${BASE_URL}api/UserAssessment/getAssessmentResults`,
        compiler: `${BASE_URL}api/Compiler`,
        evidence_report: `${BASE_URL}api/UserAssessment/saveEvidenceReport`,
        registerCompany: `${BASE_URL}api/RegisterCompany`,
        getAssessmentQuestionsView: `${BASE_URL}api/AssessmentQuestions/AssessmentQuestionsView`,
        saveAssessmentQuestion: `${BASE_URL}api/SaveAssessmentQuestion`,
        get_evidence_report: `${BASE_URL}api/UserAssessment/getEvidenceReport`,
        getUserAssessmentEvidenceDetails: `${BASE_URL}api/UserAssessment/getEvidenceDetails`,
        saveUserAssessmentEvidenceDetails: `${BASE_URL}api/UserAssessment/saveEvidenceDetails`,
        userAssessmentUpload: `${BASE_URL}api/UserAssessment/UploadId`,
        summaryRport: `${BASE_URL}api/UserAssessment/getAssessmentSummaryReport`,
        getPrimaryDomain: `${BASE_URL}api/PrimaryDomain/getPrimaryDomains`,
        getPrimaryDomainById: `${BASE_URL}api/PrimaryDomain/getPrimaryDomainById`,
        savePrimaryDomain: `${BASE_URL}api/PrimaryDomain/SavePrimaryDomain`,
        getAssDomainByPrimDomain: `${BASE_URL}api/PrimaryDomain/getAllAssessmentDomainsByPrimaryDomainId`,
        getJwtToken: `${BASE_URL}api/Account/getJWTToken`,
        addAssessmentDetails: `${BASE_URL}api/Assessment/postAssessmentDetails`,
        freezeAssessment: `${BASE_URL}api/Assessment/freezeAssessment`,
        getAssessmentDetailsForTest: `${BASE_URL}api/Assessment/getAssessmentDetailsForTest`,
        getProctorResults: `${BASE_URL}api/proctor/getProctorResults`,
        getSkillReport: `${BASE_URL}api/Questions/GetSkillReport`,
        downloadProcotorPdf : `${BASE_URL}api/proctor/downloadPdf`,
        clients:  `${BASE_URL}api/Clients`,
        dashboardDetails: `${BASE_URL}api/Dashboard/getDashboardDetails`,
        clientAssessmentReport: `${BASE_URL}api/Dashboard/getClientAssessmentReport`
    },
};