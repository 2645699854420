<h1 class="pageTitle">Dashboard</h1>
<app-loader *ngIf="isLoading">{{ isLoading }}</app-loader>
<div class="inner-content" *ngIf="isCandidate">
    <table class="table table-hover" *ngIf="isCandidate">
        <thead class="bordered-darkorange">
            <tr>
                <th>Assessment Name</th>
                <th>Status</th>
                <th>Date</th>
                <th>Company</th>
                <th>Duration</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let assessment of userAssessmentData">
                <td>{{assessment.AssessmentName}}</td>
                <td>{{ replaceStatusByName(assessment.UserAssessmentStatusId)}}</td>
                <td>{{assessment.CreatedDate | date}}</td>
                <td>{{assessment.ClientNmae}}</td>
                <td>{{assessment.Duration}} {{ assessment.Duration && assessment.Duration > 1 ? 'mins' : ''}}</td>
                <td class="assessmentAction">
                    <a title="" (click)="startUserAssessment(assessment)" class="grid-anchor green"
                        *ngIf="assessment.UserAssessmentStatusId.toString() === userAssessmentStatus.Start">
                        <i class="far fa-check-circle"></i>Start Assessment
                    </a>
                    <a title="" class="shiny"
                        *ngIf="assessment.UserAssessmentStatusId.toString() === userAssessmentStatus.Pause || assessment.UserAssessmentStatusId.toString() === userAssessmentStatus.Restart || assessment.UserAssessmentStatusId.toString() === userAssessmentStatus.Started"
                        [disabled]="true">
                        <i class="far fa-check-circle"></i>Assessment Disabled
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>